import React, { useCallback, useState } from "react"
import axios from "axios"
import { useDropzone } from "react-dropzone"
import { Button, CircularProgress, Typography } from "@mui/material"
import { useMutation } from "react-query"

function FileUpload() {
  const [file, setFile] = useState()
  const [uploadProgress, setUploadProgress] = useState(0)

  const { mutate, isLoading } = useMutation((data) =>
    axios.post(
      `load-file`,
      { file: data?.[0] },
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
        baseURL: process.env.REACT_APP_BASE_URL,
        onUploadProgress: (progressEvent) => {
          const percentCompleted = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          )
          return setUploadProgress(percentCompleted)
        },
      }
    )
  )

  const onDrop = useCallback(
    (acceptedFiles) => {
      setFile(acceptedFiles?.[0])
      mutate(acceptedFiles)
    },
    [mutate]
  )

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop })

  return (
    <div
      {...getRootProps()}
      style={{
        border: isDragActive ? "1px groove gray" : "1px dashed black",
        height: "8em",
        width: "40%",
        borderRadius: ".5em",
        textAlign: "center",
        color: "gray",
      }}
    >
      <input {...getInputProps()} />

      {file && (
        <Typography variant="body1" mt="1em" color="primary">
          {file.name}
        </Typography>
      )}
      {isLoading && (
        <CircularProgress
          height=".1em"
          variant="determinate"
          value={uploadProgress}
          sx={{ color: "black", mt: ".3em" }}
        />
      )}
      {isDragActive
        ? !isLoading && <h4>Drop the files...</h4>
        : !isLoading && (
            <Typography textTransform="uppercase">
              Drag and drop here, or{" "}
              <Button sx={{ textDecoration: "underline" }}>
                click to select files
              </Button>
            </Typography>
          )}
    </div>
  )
}

export default FileUpload
