import { Box, Button, Divider, Drawer, Typography } from "@mui/material"
import { QueryClient, QueryClientProvider } from "react-query"
import FileUpload from "./fileUpload"
import { useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
  // Function to handle Stop Live button click
 
function App() {
  const queryClient = new QueryClient();
  const [loading, setLoading] = useState(false);
   // Function to handle Stop Live button click
   const handleStopLive = async () => {
    setLoading(true);
    try {
      const response = await fetch(`${process.env.REACT_APP_BASE_URL}/stop-live`, {
        method: "POST",
      });

      if (response.status===200||response.status===201) {
        toast.success("Live bot stopped successfully", {
          position: "top-right",
          autoClose: 3000, // Automatically close after 3 seconds
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
        });
      } else {
        toast.error("Failed to stop live bot", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
        });
      }
    } catch (error) {
      toast.error("Error stopping live bot: " + error.message, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
      });
    } finally {
      setLoading(false);
    }
  };
  return (
    <div
      style={{
        display: "flex",
        gap: "3em",
        alignItems: "center",
      }}
    >
      <QueryClientProvider client={queryClient}>
        <Drawer
          variant="permanent"
          anchor={"left"}
          open={true}
          PaperProps={{
            sx: {
              width: 240,
              height: "100vh",
              bgcolor: "#1e1e2d",
              color: "white",
            },
          }}
        >
          <Typography variant="h4" ml="30%" my=".5em">
            xInt
          </Typography>
          <Divider
            sx={{
              width: "100%",
              color: "white",
              bgcolor: "lightgray",
            }}
          />
          <Typography ml="1em" mt="1em">
            ...
          </Typography>
        </Drawer>
        <Box sx={{ flexGrow: 1, ml: 30 }}>
          <Typography variant="h5" fontWeight="bold" mb="1em" mt="2em">
            Upload Config Files
          </Typography>
          <Box sx={{ ml: "2em" }}>
            <FileUpload />
          </Box>
          <Typography variant="h5" fontWeight="bold" mb="1em" mt="1em">
            Download Decision File
          </Typography>
          <a href={`${process.env.REACT_APP_BASE_URL}/export`} download>
            <Button variant="contained" sx={{ borderRadius: "2em", ml: "2em" }}>
              Download csv
            </Button>
          </a>
          <Typography variant="h5" fontWeight="bold" mb="1em" mt="3em">
            Download Streams File
          </Typography>
          <a href={`${process.env.REACT_APP_BASE_URL}/export/streams`} download>
            <Button variant="contained" sx={{ borderRadius: "2em", ml: "2em" }}>
              Download csv
            </Button>
          </a>
          <Typography variant="h5" fontWeight="bold" mb="1em" mt="3em">
            Stop Running Live Bot 
          </Typography>
          <Button
            variant="contained"
            sx={{
              borderRadius: "2em",
              ml: "2em",
              backgroundColor: "red", // Set button background to red
              "&:hover": {
                backgroundColor: "darkred", // Darken on hover
              },
            }}
            onClick={handleStopLive}
            disabled={loading}
          >
            {loading ? "Stopping..." : "Stop Live"}
          </Button>
        </Box>
      </QueryClientProvider>
      {/* Toast Container for notifications */}
      <ToastContainer />
    </div>
  )
}

export default App
